import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { IPropertyCardComponent } from "shared/models/interfaces/components/property-card.interface";
import withAdapter from "./withAdapter";
import FavoritePropertyButton from "./favoriteButton";
import PropertyItemPrice from "./PropertyItemPrice";

function PropertyCard(props: IPropertyCardComponent) {
  const {
    image,
    isExclusive,
    typePropertyLabel,
    city,
    street,
    businessTypeLabel,
    price,
    measures,
    parkingLots,
    bathing,
    bedrooms,
    code,
    parent_id,
    sector,
    is_favorite,
    is_collection,
    minimum_price,
    maximum_price,
    combineLabel = false,
    has_trust_entity = false,
    rent_price,
    type,
    ...rest
  } = props;
 
  return (
    <PropertyItem {...rest}>
      <PropertyItemLink>
        <PropertyItemThumbnail>
          <FavButtonWrapper>
            <FavoritePropertyButton
              parent_id={parent_id}
              is_favorite={is_favorite}
              detail={props}
            />
          </FavButtonWrapper>
          <PropertyItemImage src={image} />

          {isExclusive && !combineLabel && (
            <PropertyExclusiveLabel>Exclusivo</PropertyExclusiveLabel>
          )}
          {combineLabel && (
            <>
              {isExclusive && !is_collection && (
                <PropertyExclusiveLabel>Exclusivo</PropertyExclusiveLabel>
              )}
              {!isExclusive && is_collection && (
                <PropertyExclusiveLabel>
                  The RE/MAX collection
                </PropertyExclusiveLabel>
              )}
              {isExclusive && is_collection && (
                <PropertyExclusiveLabel>
                  Exclusivo / Collection
                </PropertyExclusiveLabel>
              )}
            </>
          )}
        </PropertyItemThumbnail>
        <PropertyItemDetails>
          <PropertyItemDescription>
            {typePropertyLabel}{" "}
          </PropertyItemDescription>
          <PropertyItemAddress>
            {sector}, {city}
          </PropertyItemAddress>
          <PropertyItemType>
            {businessTypeLabel}
            {has_trust_entity && <Fideo src="/icons/fideicomiso.svg" />}
          </PropertyItemType>

          <PropertyItemPrice
            prices={{ price, minimum_price, maximum_price, rent_price ,type}}
          ></PropertyItemPrice>
          <PropertyItemFeatures measures={measures}>
            <PropertyItemFeature>
              <PropertyItemIcon src="/icons/icon_car_remaxrd.svg" />{" "}
              {parkingLots}
            </PropertyItemFeature>
            <PropertyItemFeature>
              <PropertyItemIcon src="/icons/icon_bathtub_remaxrd.svg" />{" "}
              {bathing}
            </PropertyItemFeature>
            <PropertyItemFeature>
              <PropertyItemIcon src="/icons/icon_bed_remaxrd.svg" /> {bedrooms}
            </PropertyItemFeature>
            <PropertyItemFeature
              style={{ whiteSpace: "nowrap", paddingLeft: 1 }}
            >
              <PropertyItemIcon src="/icons/icon_rule_remaxrd.svg" />{" "}
              <span>
                {measures} M<sup className="sup">2</sup>
              </span>
            </PropertyItemFeature>
          </PropertyItemFeatures>
          <PropertyItemCode>Cod. {code}</PropertyItemCode>
        </PropertyItemDetails>
      </PropertyItemLink>
    </PropertyItem>
  );
}

//#region Card styles
const PropertyItem = styled.div`
  width: 270px;
  min-height: 400px;
  max-height: 440px;
  background-color: #fff;
  border-radius: 18px;
  overflow: hidden;
  transition: box-shadow 0.4s;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 15px 2px #0005;
  }
`;

const Fideo = styled.img`
  min-width: 100px;
  height: auto;
  object-fit: cover;
  object-position: center;
`;
const PropertyItemLink = styled.a`
  background-color: transparent;
  text-decoration: none;
`;

const PropertyItemThumbnail = styled.div`
  width: 100%;
  height: 170px;
  overflow: hidden;
  position: relative;
  text-align: center;
`;

const PropertyItemDetails = styled.div`
  padding: 15px 20px 10px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 170px);
`;

const PropertyItemImage = styled.img`
  width: 100%;
  vertical-align: middle;
  border-style: none;
`;

const propertyItemLabelStyles = css`
  position: absolute;
  bottom: 0;
  left: 0;
  width: auto;
  min-width: 45%;
  text-align: center;
  padding: 5px 0;
  font-weight: 600;
  font-size: ${11 / 16 + "rem"};
  text-transform: uppercase;
`;

const PropertyExclusiveLabel = styled.span`
  ${propertyItemLabelStyles}
  background-color: #e92027;
  color: #fff;
  padding: 5px 10px;
`;

const PropertyItemDescription = styled.h3`
  font-size: ${16 / 16 + "rem"};
  margin-bottom: 5px;
  text-transform: capitalize;
  white-space: nowrap;
`;

const PropertyItemDescriptionLight = styled.span`
  font-weight: 300;
`;

const PropertyItemAddress = styled.span`
  font-size: ${10 / 16 + "rem"};
  display: block;
  margin-bottom: 15px;
  color: #808080;
  text-transform: capitalize;
  font-weight: bold;
`;

const PropertyItemType = styled.span`
  font-size: ${16 / 16 + "rem"};
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 300;

  text-transform: uppercase;
  line-height: 20px;
  margin-bottom: 10px;
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
`;

const PropertyItemCode = styled.span`
  display: block;
  text-align: center;
  color: #e92027;
  font-weight: 600;
  font-size: ${11 / 16 + "rem"};
  margin-top: auto;
`;

const PropertyItemFeatures = styled.ul`
  list-style: none;
  margin-bottom: 10px;

  ${(props: { measures: number }) => {
    if (props.measures.toString().length >= 4) {
      return css`
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        ${PropertyItemFeature} {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          ${PropertyItemIcon} {
            margin: 0;
            margin-bottom: 5px;
          }
        }
      `;
    }
  }}
`;

const PropertyItemFeature = styled.li`
  display: inline-block;
  margin-right: 7px;
  font-size: ${7.5 / 16 + "rem"};
  color: #808080;
  font-weight: 400;
`;

const PropertyItemIcon = styled.img`
  width: 50px;
  max-width: 25px;
  vertical-align: middle;
  margin-right: 5px;
`;

const FavButtonWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  background: white;
  clip-path: circle();
`;

//#endregion End of Card styles

export default withAdapter(PropertyCard);
