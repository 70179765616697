import styled from "@emotion/styled";
import { useTranslations } from "next-intl";
import A from "shared/context/filters-property.context";

interface IPropertyItemPriceProps {
  prices: {
    price: string | number;
    minimum_price?: string;
    maximum_price?: string;
    rent_price?: string;
    type: "venta" | "alquiler" | "venta y alquiler";
  };
}

export default function PropertyItemPrice(props: IPropertyItemPriceProps) {
  const t = useTranslations("PriceRange");
  const { price, maximum_price, minimum_price, rent_price, type } =
    props.prices;

  if (maximum_price && minimum_price) {
    return (
      <div>
        <PriceRangeItem>
          {t("from")} <PriceRangeItemPrice>{minimum_price}</PriceRangeItemPrice>
        </PriceRangeItem>
        <PriceRangeItem>
          {t("to")} <PriceRangeItemPrice>{maximum_price}</PriceRangeItemPrice>
        </PriceRangeItem>
      </div>
    );
  }

  if (type == "venta") {
    return (
      <div>
        <PriceRangeItem>
          VENTA <PriceRangeItemPrice>{price}</PriceRangeItemPrice>
        </PriceRangeItem>
      </div>
    );
  }

  if (type == "alquiler") {
    return (
      <div>
        <PriceRangeItem>
          <PriceRangeItemPrice>{rent_price}</PriceRangeItemPrice>
        </PriceRangeItem>
      </div>
    );
  }

  if (type == "venta y alquiler") {
    return (
      <div>
        <PriceRangeItem>
          VENTA <PriceRangeItemPrice>{price}</PriceRangeItemPrice>
        </PriceRangeItem>
        <PriceRangeItem>
          ALQUILER <PriceRangeItemPrice>{rent_price}</PriceRangeItemPrice>
        </PriceRangeItem>
      </div>
    );
  }

  return (
    <div>
      <StyledSinglePrice>{price}</StyledSinglePrice>
    </div>
  );
}

const StyledSinglePrice = styled.span`
  font-weight: 700;
  display: block;
  font-size: ${19 / 16 + "rem"};
  margin-bottom: 20px;
  text-transform: uppercase;
`;

const PriceRangeItem = styled.p`
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 5px;
  text-transform: uppercase;
`;

const PriceRangeItemPrice = styled.span`
  font-weight: 700;
  font-size: 1.1rem;

  @media (max-width: 500px) {
    font-size: 1.4rem;
  }
`;
